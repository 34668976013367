import React from "react"
import TemplatePageGuide from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide88/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Estrategias de automatización en la gestión de devoluciones logísticas
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/devoluciones-logisticas/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Aprende cómo implementar estrategias para automatizar la gestión de devoluciones en logística y optimizar procesos en tu empresa."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Estrategias de automatización en la gestión de devoluciones logísticas"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/devoluciones-logisticas/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia88.jpg`}
      />
      <meta
        property="og:description"
        content="Aprende cómo implementar estrategias para automatizar la gestión de devoluciones en logística y optimizar procesos en tu empresa."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Logística"
      location={location}
      typeGuide="guide88"
      clickHereHref="https://beexcc.com/blog/"
      data={data}
    />
  </div>
)

export default IndexPage
