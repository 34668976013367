import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide88/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide88/image/img.png"
import slider1 from "@components/pageGuide/guides/guide88/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide88/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide88/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Logística ",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Estrategias de automatización en la gestión de devoluciones logísticas
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      La automatización ayuda a mejorar el manejo de devoluciones en logística. Optimiza tiempos y reduce errores en cada etapa del proceso. Implementa sistemas para registrar solicitudes y dar seguimiento en tiempo real. Usa notificaciones automáticas para informar a clientes sobre el estado de sus devoluciones.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Integración de sistemas y digitalización de operaciones logísticas.",
      },
      {
        key: 2,
        text: "Optimización de procesos logísticos."
      },
      {
        key: 3,
        text: "Estrategias de automatización en la gestión de devoluciones.",
      },
      {
        key: 4,
        text: "Innovación en la cadena de suministro.",
      },
      {
        key: 5,
        text: " Desafíos y consideraciones en la transformación digital en logística.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás conocer cómo integrar herramientas para clasificar productos y agilizar reembolsos. Asegura que cada paso se ejecute sin retrasos y con menor intervención manual.
          <br /><br />
          Por último, descubre más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Mejora la eficiencia en la gestión de devoluciones.

        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
